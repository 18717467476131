let list    = document.querySelector(".btn-list");
let write   = document.querySelector(".btn-write");
let modify  = document.querySelector(".btn-modify");
let del     = document.querySelector(".btn-del");

if(list !== null) {
   list.addEventListener("click", () => {
      location.href = "/board";   
   });
}

if(write !== null) {
   write.addEventListener("click", () => {
      location.href = "/board/create";
   });
}

if(modify !== null) {
   modify.addEventListener("click", () => {
      document.getElementById("pwdWrapper").style.display = "block";
      let btn = document.querySelector(".btn-ok");
      btn.addEventListener("click", () => {
         document.getElementsByName("type")[0].value = "modify";
         PassWord();
      });
   });
}

if(del !== null) {
   del.addEventListener("click", () => {
      document.getElementById("pwdWrapper").style.display = "block";
      let btn = document.querySelector(".btn-ok");
      btn.addEventListener("click", () => {
         document.getElementsByName("type")[0].value = "del";
         PassWord();
      });
   });
}

const PassWord = () => {
    fetch("/board/pwd", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": $("meta[name=csrf-token]").attr("content"),
      },
      body: JSON.stringify({
          num:    document.getElementsByName("num")[0].value,
          pass:   document.getElementsByName("password")[0].value,
          type:   document.getElementsByName("type")[0].value,
      }),
    })
    .then((response) => response.json())
    .then((data) => {
        if(data.res == 200) {
            if(data.type == "del") {
                alert("삭제되었습니다.");
            }
            location.href = data.url;
        } else {
            alert("비밀번호가 일치하지 않습니다.");
        }
    })
}